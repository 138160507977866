body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: black !important;
}

a:hover {
    text-decoration: none !important;
}

a p:hover {
    background: -webkit-linear-gradient(270deg, #FFFFFF 46.37%, rgba(51, 51, 51, 0.69) 131.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.rfm-child {
    margin: 0 24px;
}

.theme-box-shadow {
    box-shadow: 1px -1px #FFFFFF30;
}

.theme-gradient-text {
    background: -webkit-linear-gradient(96.08deg, #6459E3 50.06%, #BAA7ED 88.46%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.black-gradient-text {
    background: -webkit-linear-gradient(270deg, #FFFFFF 46.37%, rgba(51, 51, 51, 0.69) 131.5%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.zero-gradient-text {
    background: -webkit-linear-gradient(270deg, #8A8A8A 0%, rgba(138, 138, 138, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.footer-bg {
    background-position: 0 30%;
    background-repeat: no-repeat;
    /*animation: animatedBackground 10s linear infinite alternate;*/
}

@keyframes animatedBackground {
    from {
        background-position: 0 30%;
    }
    to {
        background-position: -500% 30%;
    }
}