.header {
    background-clip: border-box;
    border: 1px solid transparent;
    position: relative;
    border-radius: 42px;
    box-sizing: border-box;
}

.header:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: -1; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(to right, red, orange);
}