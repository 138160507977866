@keyframes floatShadowAnimation {
    0% {
        box-shadow: 0 5px 15px 0 inherit;
        transform: translatey(0px);
    }
    25% {
        box-shadow: 5px 0 25px 0 inherit;
        transform: translatey(-5px);
    }
    50% {
        box-shadow: 0 -5px 15px 0 inherit;
        transform: translatey(0px);
    }
    75% {
        box-shadow: -5px 0 30px 0 inherit;
        transform: translatey(-5px);
    }
    100% {
        box-shadow: 0 5px 15px 0 inherit;
        transform: translatey(0px);
    }
}

html,
body,
#root,
#root > div[data-rk] {
    height: 100%;
    width: 100%;
    display: flex;
    flex: 1;
    color: white;
}

.float-image {
    transition: 1s ease;
    animation-name: floatImage;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

@keyframes floatImage {
    0% {
        transform: translateY(10px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(10px);
    }
}
